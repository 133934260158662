import {
  Box,
  Typography,
  Button,
  Divider,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import {
  CancelOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  RefreshOutlined,
  SaveOutlined,
} from "@mui/icons-material";

import { now } from "moment";

import {
  deleteCataloguePage,
  getCatalogue,
  getCataloguePages,
  updateCataloguePage,
  updateCataloguePageSequenceParent,
} from "slices/catalogue";

import NiceMarkdown from "components/global/NiceMarkdown";

import { clearMessage } from "slices/message";
import CataloguePageAdd from "components/catalogue/CataloguePageCreate";
import ConfirmationDialog from "components/global/ConformationDialogue";
import CataloguePageEditor from "components/catalogue/CataloguePageEditor";
import CataloguePageMenu from "components/catalogue/CatalogPageMenu";
import CataloguePageDetail from "components/catalogue/CataloguePageDetail";

const buildPageTree = (pages) => {
  const pageMap = new Map();
  let maxSequence = -Infinity;

  pages.forEach((page) => {
    pageMap.set(page.id, { ...page, children: [] });
    if (page.sequence > maxSequence) {
      maxSequence = page.sequence;
    }
  });

  const tree = [];
  pageMap.forEach((page) => {
    if (page.parentPage) {
      const parent = pageMap.get(page.parentPage);
      if (parent) parent.children.push(page);
    } else {
      tree.push(page);
    }
  });

  return { tree, maxSequence };
};

const Catalogue = (props) => {
  const { id } = useParams();
  const { pageId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const catalogue = useSelector((state) =>
    state.catalogue.catalogue.data.list?.find((element) => element.id === id)
  );

  const configRef = React.useRef(null);

  const [parentPage, setParentpage] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [deletePage, setDeletePage] = useState(null);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    dispatch(deleteCataloguePage({ id: deletePage.id }));
    setOpenDialog(false);
  };

  const [editMode, setEditMode] = useState(false);
  const [pageHasUpdates, setPageHasUpdates] = useState(false);
  const [newContent, setNewContent] = useState("");

  const [anchorElDetail, setAnchorElDetail] = useState(null);
  const areaDetailHeight = "65vh";
  const areaDetailWidth = "30vw";

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const areaHeight = "65vh";
  const areaWidth = "30vw";

  const handelCloseAddEdit = (newPage) => {
    setAnchorEl(null);

    if (newPage) {
      navigate(`/catalogue/${page.catalogueId}/${newPage}`);
    }
  };

  const handleDetailPageOpen = () => {
    setAnchorElDetail(configRef.current);
  };

  const handleDetailPageClose = () => {
    setAnchorElDetail(null);
  };

  const open = Boolean(anchorEl);
  const openDetail = Boolean(anchorElDetail);

  document.title = catalogue?.name;

  const pages = useSelector((state) => state.catalogue.page.data.list);

  //console.log("pages: ", pages )

  //Build the list of pages as a tree
  const result = buildPageTree(pages);
  const pageTree = result.tree;

  const [sequence, setSequence] = useState(result.maxSequence);

  let page = null;

  //debugger
  if (pageId) {
    page = pages?.find((page) => page.catalogueId === id && page.id === pageId);
  } else {
    //No id so open the first visible page based on sequcence
    page = pages
      ?.filter(
        (page) =>
          page.catalogueId === id &&
          page.hideContent === false &&
          page.parentPage === ""
      )
      .sort((a, b) => a.sequence - b.sequence)[0];
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelOpenAddEdit = ({ parentPage, sequence }) => {
    dispatch(clearMessage());

    if (parentPage) {
      setParentpage(parentPage);
    } else {
      setParentpage(null);
    }

    if (sequence) {
      setSequence(sequence);
    } else {
      setSequence(result.maxSequence);
    }

    setAnchorEl(configRef.current);
  };

  const handelDeletePage = (id) => {
    const pageToDelete = pages.find((page) => page.id === id);
    setDeletePage(pageToDelete);
    setOpenDialog(true);
  };

  const handleUpdateParentAndSequenceOnPage = ({
    id,
    sequence,
    parentPage,
  }) => {
    const cataloguePage = {
      id: id,
      sequence: sequence,
      parentPage: parentPage,
    };

    dispatch(updateCataloguePageSequenceParent({ cataloguePage }));
  };

  const handleEditPage = (id) => {
    //first make sure we are on the right page
    const pageToEdit = pages.find((page) => page.id === id);

    navigate(`/catalogue/${pageToEdit.catalogueId}/${pageToEdit.id}`);

    setEditMode(true);
    setEditPageName(page.name);
  };

  const handleExitEditMode = () => {
    setEditMode(false);
    setPageHasUpdates(false);
    setNewContent("");
  };

  const handelSaveContent = () => {
    const cataloguePage = {
      id: page.id,
      name: editPageName,
    };

    //only set the page content if the page content has been chnaged,
    if (newContent) {
      cataloguePage.content = newContent;
    }

    dispatch(updateCataloguePage({ cataloguePage }));
    handleExitEditMode();
  };

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  //useInterval(() => {}, 1000);

  // useEffect(() => {
  //   dispatch(getCatalogue({ id }));

  //   const search = {
  //     id: id,
  //     idField: "catalogueId",
  //     sort: "sequence",
  //     page: 0,
  //     pageSize: 100000,
  //   };

  //   dispatch(getCataloguePages({ search }));
  // }, [refreshPoint, dispatch, id]);

  useEffect(() => {
    if (!catalogue) {
      dispatch(getCatalogue({ id }));
    }

    const search = {
      id: id,
      idField: "catalogueId",
      sort: "sequence",
      page: 0,
      pageSize: 100000,
    };

    dispatch(getCataloguePages({ search }));
  }, [refreshPoint, dispatch, id, catalogue]);

  useEffect(() => {
    if (!pageId && catalogue && page) {
      //debugger
      navigate(`/catalogue/${page.catalogueId}/${page.id}`);
    }
  }, [catalogue, navigate, page, pageId, pages]);

  const menuWidth = 360;

  const [editPageName, setEditPageName] = useState(page?.name);
  const [error, setError] = useState(false);

  const handlePageNameChange = (event) => {
    const value = event.target.value;
    setEditPageName(value);
    setPageHasUpdates(true);

    // Check if the field is empty
    if (value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  // Constants representing page types
  const PageTypes = {
    PageTypeStandard: "Standard",
    PageTypeGeneralPatternGroup: "General Pattern",
    PageTypeServiceCategoryGroup: "Service Category",
    PageTypeServiceGroup: "Service",
    PageTypeGeneralSavingCategoryGroup: "Saving Category - General",
    PageTypeSavingCategoryGroup: "Saving Category",
    PageTypeToolGroup: "Tool Group",
    PageTypePatternGeneral: "Pattern - General",
    PageTypePatternSavingCategory: "Pattern - Saving Category",
    PageTypeTool: "Tool",
    PageTypeScript: "Script",
  };

  let displayContent = page?.content;

  //if the catalogue is of type 'Pattern Library' add the hirachey bar to the top of the diplay page
  if (catalogue?.type === "Pattern Library") {
    //Service Category: Compute -> Service: Azure App Service -> Product Group: PremiumV3, Isolated, IsolatedV2 (Use for ASEv3), IsolatedV2 (Dedicated Host) -> Saving Category: Discount -> Pattern: Reservation for Azure App Services -> Saving Type: Reservations -> Saving Model: 1 Year Reservation, 3 Year Reservation.

    let pageContext = "";

    // Logic to build the pageContext string based on page types
    if (page?.pageType === PageTypes.PageTypeServiceCategoryGroup) {
      pageContext += `**Pattern Hierarchy**\n\n **Service Category:** ${page.serviceCategory}`;
    }

    if (
      page?.pageType === PageTypes.PageTypeServiceGroup ||
      page?.pageType === PageTypes.PageTypeToolGroup
    ) {
      pageContext += `**Pattern Hierarchy**\n\n **Service Category:** ${page.serviceCategory} | **Service:** ${page.service}`;
    }

    if (page?.pageType === PageTypes.PageTypeSavingCategoryGroup) {
      pageContext += `**Pattern Hierarchy**\n\n **Service Category:** ${page.serviceCategory} | **Service:** ${page.service} | **Saving Category:** ${page.savingCategory}`;
    }

    if (page?.pageType === PageTypes.PageTypePatternSavingCategory || page?.pageType === PageTypes.PageTypeScript) {

      pageContext += `**Pattern Hierarchy**\n\n **Service Category:** ${page.serviceCategory} | **Service:** ${page.service} | **Saving Category:** ${page.savingCategory} | **Saving Groups:** ${page.savingGroups?.join(", ")} | **Saving Models:** ${page.savingModels?.join(", ")} | **Actions:** ${page.actions?.join(", ")} | **Product Groups:** ${page.productGroups?.join(", ")} `;

    }

    if (page?.pageType === PageTypes.PageTypeTool) {
      pageContext += `**Pattern Hierarchy**\n\n **Service Category:** ${page.serviceCategory} | **Service:** ${page.service}`;
    }

    pageContext += "\n\n";
    displayContent = pageContext + displayContent;
  }

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs
          lastOverride={
            pageId ? page?.name : catalogue?.type + " - " + catalogue?.name
          }
          penultimateOverride={
            pageId ? catalogue?.type + " - " + catalogue?.name : null
          }
        />

        <Box
          display={"flex"}
          width={"100%"}
          minHeight={"88vh"}
          gap={".3rem"}
          mt={"20px"}
          sx={{ borderTop: 1, borderColor: "divider", flexGrow: 1 }}
        >
          {/* check if we are in edit mode, if we are hide the Menu  */}
          {!editMode && (
            <Box
              mt=".3rem"
              display={"flex"}
              width={`${menuWidth}px`}
              minHeight={"100%"}
              flexDirection={"column"}
              gap={".3rem"}
              sx={{
                //borderBottom: 1,
                borderRight: 1,
                borderColor: "divider",
                flexGrow: 1,
                overflowY: "auto", // Enable vertical scrolling if needed
              }}
            >
              <Typography variant="h3">{catalogue?.name}</Typography>

              <CataloguePageMenu
                pageTree={pageTree}
                selectedPage={pageId}
                addPageHandler={handelOpenAddEdit}
                catalogue={catalogue}
                pages={pages}
                deletePageHandler={handelDeletePage}
                editPageHandler={handleEditPage}
                menuWidth={menuWidth}
                parentPageHandler={handleUpdateParentAndSequenceOnPage}
              />
            </Box>
          )}

          <Box
            mt=".3rem"
            ml="1rem"
            gap="1rem"
            sx={{
              //borderBottom: 1,

              width: "100%",
              display: "flex",
              flexGrow: 1,
            }}
            flexDirection={"column"}
          >
            <Box
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"space-between"}
              pr={".3rem"}
            >
              {/* check if we are in edit mode, if we are hide the Menu  */}
              {!editMode && (
                <>
                  <Typography variant="h1">{page?.name}</Typography>

                  <Box display={"flex"} gap={".3rem"} flexDirection={"row"}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handelRefresh}
                    >
                      <RefreshOutlined />
                    </Button>

                    <Divider orientation="vertical" flexItem />

                    <Tooltip
                      title={
                        "See details of page, such as type and relationships"
                      }
                      enterDelay={300}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDetailPageOpen()}
                      >
                        <InfoOutlined />
                      </Button>
                    </Tooltip>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEditPage(page.id)}
                    >
                      <EditOutlined />
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handelDeletePage(page.id)}
                    >
                      <DeleteOutlined />
                    </Button>

                    <Box ref={configRef} />
                  </Box>
                </>
              )}

              {editMode && (
                <>
                  <TextField
                    size="small"
                    id="name_edit"
                    value={editPageName}
                    onChange={handlePageNameChange}
                    sx={{ width: "60%" }}
                    required
                    error={error} // Control the error state
                    helperText={error ? "This field is required" : ""}
                  />

                  <Box display={"flex"} gap={".3rem"} flexDirection={"row"}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handelSaveContent}
                      startIcon={<SaveOutlined />}
                      disabled={!pageHasUpdates}
                    >
                      Save
                    </Button>

                    <Button
                      variant="outlined"
                      onClick={handleExitEditMode}
                      startIcon={<CancelOutlined />}
                    >
                      {pageHasUpdates ? "Discard Changes" : "Cancel Edit"}
                    </Button>

                    <Box ref={configRef} />
                  </Box>
                </>
              )}
            </Box>

            {editMode ? (
              <CataloguePageEditor
                page={page}
                handleSetPageUpdated={setPageHasUpdates}
                handleUpdateContent={setNewContent}
              />
            ) : (
              <NiceMarkdown>{displayContent}</NiceMarkdown>
            )}
          </Box>
        </Box>
      </PageBlock>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handelCloseAddEdit()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <CataloguePageAdd
            closerFunc={handelCloseAddEdit}
            catalogueId={id}
            page={parentPage}
            sequence={sequence + 1}
            pagesFullDetail={pages}
          />
        </Box>
      </Popover>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Deletion"
        content={'Are you sure you want to delete "' + deletePage?.name + '" ?'}
        confirmeColour="error"
      />

      <Popover
        open={openDetail}
        anchorEl={anchorElDetail}
        onClose={() => handleDetailPageClose()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaDetailHeight} width={areaDetailWidth}>
          <CataloguePageDetail
            page={page}
            catalogue={catalogue}
            closerFunc={handleDetailPageClose}
          />
        </Box>
      </Popover>
    </>
  );
};

export default Catalogue;
